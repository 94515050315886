import React, { FC } from "react";
import BlockContent from "@sanity/block-content-to-react";
import cx from "classnames";

import { SanityBlock } from "lib/cms";

interface Props {
  blocks: SanityBlock[];
  className?: string;
  indent?: boolean;
}

const PortableText: FC<Props> = ({ blocks, className, indent = true }) => {
  const headingClassName = cx("font-bold text-medium md:text-base", {
    "md:-ml-4": indent,
  });

  const serializers = {
    types: {
      block: (props: any) => {
        const { style = "normal" } = props.node;

        if (style === "h1") {
          return <h1 className={headingClassName}>{props.children}</h1>;
        }

        if (style === "h2") {
          return <h2 className={headingClassName}>{props.children}</h2>;
        }

        if (style === "h3") {
          return <h3 className={headingClassName}>{props.children}</h3>;
        }

        return BlockContent.defaultSerializers.types.block(props);
      },
    },
  };

  return (
    <div className={cx("PortableText", className, { "md:pl-4": indent })}>
      <BlockContent blocks={blocks} serializers={serializers} />
    </div>
  );
};

export default PortableText;
